window.$ = window.jQuery = require('jquery');

require('jquery-viewport-checker');
require('bootstrap');
require('bootstrap-datepicker');
require('leaflet');
//require('slick-carousel');
require("./simple-lightbox");


